import React, { useState, useEffect } from "react";

const IPAccessControl = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientIP, setClientIP] = useState(null);

  // Whitelist of allowed IP addresses
  const allowedIPs = ["185.241.225.78", "193.115.201.75"];

  useEffect(() => {
    const checkIPAccess = async () => {
      try {
        // Fetch the client's IP address
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setClientIP(data.ip);
        setIsAuthorized(allowedIPs.includes(data.ip));
      } catch (error) {
        console.error("Error fetching IP:", error);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    checkIPAccess();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ fontSize: "1.25rem" }}>Loading...</div>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundColor: "#f9fafb",
        }}
      >
        <div
          style={{
            maxWidth: "28rem",
            padding: "1.5rem",
            backgroundColor: "#fee2e2",
            border: "1px solid #ef4444",
            borderRadius: "0.375rem",
            color: "#7f1d1d",
          }}
        >
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            Access Denied
          </h2>
          <p style={{ marginBottom: "1rem" }}>
            Error 403: You are not authorized to access this application. Please
            contact the administrator if you believe this is a mistake.
          </p>
          <div
            style={{
              padding: "0.75rem",
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "0.25rem",
              fontSize: "0.875rem",
            }}
          >
            <p>
              <strong>Your IP:</strong> {clientIP}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default IPAccessControl;
