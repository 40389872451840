import React from "react";

const TaglineSection = ({ taglineTitle, taglineText, taglineIcons }) => {
  return (
    <div className="text-center pt-10 mx-auto max-w-5xl space-y-5 px-5">
      <h3 className="text-2xl uppercase font-semibold text-fmg-dark-gray">
        {taglineTitle}
      </h3>
      <p>{taglineText}</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-x-10">
        {taglineIcons &&
          taglineIcons.map((icon, index) => (
            <div key={index}>
              <img
                src={icon.image.url}
                className="h-24 w-24 mx-auto"
                alt={icon.title}
              />
              <p className="uppercase font-semibold text-repose-blue leading-5">
                {icon.title}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TaglineSection;
