import { Tab, TabGroup, TabPanel, TabList, TabPanels } from "@headlessui/react";
import ReactPlayer from "react-player";
import getVideoId from "get-video-id";

const Gallery = ({ gallery, video }) => {
  // Check if gallery exists and has images
  const { id: video_id } = getVideoId(video) || {};

  if (!gallery || gallery.length === 0) return null;

  return (
    <div className="">
      {/* Image gallery */}
      <TabGroup as="div" className="flex flex-col-reverse">
        {/* Image selector */}
        <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none">
          <TabList className="grid grid-cols-4 gap-6">
            {gallery.map((image, index) => (
              <Tab
                key={index}
                className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
              >
                <span className="sr-only">{image.name}</span>
                <span className="absolute inset-0 overflow-hidden rounded-md">
                  <img
                    alt={image.alt}
                    src={image.url} // Assuming each image in gallery has 'url'
                    className="h-full w-full object-cover object-center transition-all duration-300 ease-in-out transform group-hover:scale-105"
                  />
                </span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-fmg-green-500"
                />
              </Tab>
            ))}

            {/* If video exists, add a tab for the video */}
            {video && (
              <Tab className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                <span className="sr-only">Product Video</span>
                <span className="absolute inset-0 overflow-hidden rounded-md">
                  <img
                    alt="Product Video"
                    src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
                    className="h-full w-full object-cover object-center transition-all duration-300 ease-in-out transform group-hover:scale-105"
                  />
                </span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-fmg-green-500"
                />
              </Tab>
            )}
          </TabList>
        </div>

        {/* Main Image/Video Display */}
        <TabPanels className="aspect-h-1 aspect-w-1 w-full">
          {gallery.map((image, index) => (
            <TabPanel
              key={index}
              className="transition-opacity duration-300 ease-in-out"
            >
              <img
                alt={image.alt}
                src={image.url}
                className="h-full w-full object-cover object-center sm:rounded-lg transition-opacity duration-300 ease-in-out"
              />
            </TabPanel>
          ))}

          {/* If video exists, render it using ReactPlayer */}
          {video && (
            <TabPanel className="transition-opacity duration-300 ease-in-out">
              <div className="aspect-h-1 aspect-w-1 w-full">
                <ReactPlayer
                  url={video}
                  controls={true}
                  width="590px"
                  height="590px"
                  className="transition-opacity duration-300 ease-in-out"
                />
              </div>
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default Gallery;
