import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function VariantSelector({ variants }) {
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  return (
    <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        Select a variant
      </legend>
      <RadioGroup
        value={selectedVariant}
        onChange={setSelectedVariant}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
      >
        {variants.map((variant) => (
          <Radio
            key={variant.id}
            value={variant}
            aria-label={variant.short_description}
            className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-fmg-green-500 data-[focus]:ring-2 data-[focus]:ring-fmg-green-500"
          >
            <span className="flex flex-1 ">
              <span className="flex flex-col justify-between">
                <span className="block text-sm font-medium text-gray-900">
                  {variant.short_description}
                </span>
                <span className="mt-1 flex items-center text-sm text-gray-500">
                  {variant.product_code}
                </span>
              </span>
            </span>
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-fmg-green-500 [.group:not([data-checked])_&]:invisible"
            />
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-fmg-green-500"
            />
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
}
