import React, { useEffect, useState } from "react";
import { fetchData } from "./utils/statamic-api";
import Loading from "./Loading";

const LogoCloud = () => {
  const [logos, setLogos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLogoCloud = async () => {
      try {
        const data = await fetchData("/globals/logo_cloud?site=de");
        setLogos(data.data.logos); // Assuming logos are under 'data.logos'
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logo cloud data:", error);
        setLoading(false);
      }
    };

    fetchLogoCloud();
  }, []);

  if (loading) return <Loading />;

  if (!logos.length) return <p>No logos available.</p>; // Fallback if no logos exist

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none ">
          {logos.map((logo, index) => (
            <img
              key={index}
              alt={logo.alt || "Partner Logo"} // Assuming 'alt' property exists
              src={logo.image.url} // Assuming 'url' is the logo source
              width={158}
              height={48}
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoCloud;
