import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeroSlider from "./HeroSlider";
import BrandLogos from "./BrandLogos";
import TaglineSection from "./TaglineSection";
import FeaturedBrands from "./FeaturedBrands.js";
import Layout from "./Layout";
import Loading from "./Loading";
import { fetchData } from "./utils/statamic-api";

function HomePage() {
  const [homePageContent, setHomePageContent] = useState(null);

  const pageId = "6d83248e-65fe-49bd-8d7a-e4b074e2b9f5";

  useEffect(() => {
    const getHomePageContent = async () => {
      try {
        const data = await fetchData(`/collections/pages/entries/${pageId}`);
        setHomePageContent(data.data);
      } catch (error) {
        console.error("Error fetching homepage content:", error);
      }
    };

    getHomePageContent();
  }, []);

  if (!homePageContent) return <Loading />;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {homePageContent.meta_title || homePageContent.title} | Frontier
            Medical Group
          </title>
          <meta
            name="description"
            content={
              homePageContent.seo.description ||
              homePageContent.meta_description
            }
          />
          <meta
            property="og:title"
            content={homePageContent.meta_title || homePageContent.title}
          />
          <meta
            property="og:description"
            content={
              homePageContent.meta_description || homePageContent.summary
            }
          />
        </Helmet>
      </HelmetProvider>
      <Layout>
        {/* Hero Slider */}
        <div className="relative z-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <HeroSlider slides={homePageContent.hero_slider} />
        </div>

        {/* Brand Logos */}
        <div className="relative z-20 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <BrandLogos logos={homePageContent.featured_logos} />
        </div>

        {/* Tagline Section */}
        <TaglineSection
          taglineTitle={homePageContent.tagline_title}
          taglineText={homePageContent.tagline_text}
          taglineIcons={homePageContent.tagline_icons}
        />

        {/* Featured Brands Section */}
        <FeaturedBrands brands={homePageContent.featured_brands} />
      </Layout>
    </>
  );
}

export default HomePage;
