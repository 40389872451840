import React, { useEffect, useState } from "react";

const HeroSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setStarted(true);
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1,
      );
    }, 3000);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [slides.length]);

  return (
    <div className="relative flex min-h-full h-[22rem] sm:h-[28rem] overflow-hidden sm:shadow-xl sm:rounded-2xl">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-transform duration-1000 ${
            started && currentIndex === index
              ? "translate-x-0"
              : "translate-x-full"
          }`}
          style={{
            transform:
              currentIndex === index ? "translateX(0)" : "translateX(100%)",
          }}
        >
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover"
              src={slide.banner.url}
              alt={slide.title}
              loading="lazy" // Native lazy loading for better performance
            />
            {/* Darker overlay for better readability */}
            <div className="absolute inset-0 bg-black opacity-30"></div>
          </div>

          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="capitalize text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-white">
              {slide.title}
            </h1>
            <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl">
              {slide.subtitle}
            </p>
            <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
              <a
                href={slide.button_link}
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-fmg-green-500 shadow-sm hover:bg-fmg-dark-gray hover:text-white sm:px-8"
              >
                {slide.button_text}
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeroSlider;
