import React from "react";

const FeaturedBrands = ({ brands }) => {
  return (
    <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
      <div className="grid gap-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        {brands.map((brand, index) => (
          <article
            key={brand.id}
            className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
          >
            <img
              alt=""
              src={brand.image.url}
              className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
            <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            <h3 className="mt-3 text-xl font-bold leading-6 text-white">
              <a href={brand.button_link}>
                <span className="absolute inset-0" />
                {brand.title}
              </a>
            </h3>
            <p className="text-white mt-3">{brand.subtitle}</p>
          </article>
        ))}
      </div>
    </div>
  );
};

export default FeaturedBrands;
