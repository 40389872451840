export default function HeaderButtons({ buttons }) {
  return (
    <div className="space-y-3">
      {buttons.map((button) => (
        <a
          key={button.id}
          className="flex w-full rounded-md items-center justify-center border border-fmg-green-500 text-fmg-green-500 hover:text-white py-3 px-8 text-base font-medium hover:bg-fmg-dark-gray focus:outline-none focus:ring-2 focus:ring-fmg-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          href={button.url}
        >
          {button.button_text}
        </a>
      ))}
    </div>
  );
}
