import React, { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons"; // Import SocialIcon from the package
import { fetchData } from "./utils/statamic-api"; // Adjust the import path as needed
import Loading from "./Loading"; // Loading component
import {
  BuildingOfficeIcon,
  PhoneIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

const Footer = ({ logo }) => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [socialLinksData, setSocialLinksData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const companyData = await fetchData("/globals/company_details?site=de");
        setCompanyInfo(companyData.data);
        const socialData = await fetchData("/globals/social?site=de");
        setSocialLinksData(socialData.data);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFooterData();
  }, []);

  if (loading) return <Loading />;

  if (!companyInfo) return <p>Company information is unavailable.</p>;

  // Social link URLs (only add if they exist)
  const socialUrls = [
    socialLinksData.facebook &&
      `https://www.facebook.com/${socialLinksData.facebook}`,
    socialLinksData.twitter && `https://twitter.com/${socialLinksData.twitter}`,
    socialLinksData.linkedin &&
      `https://www.linkedin.com/company/${socialLinksData.linkedin}`,
    socialLinksData.instagram &&
      `https://www.instagram.com/${socialLinksData.instagram}`,
    socialLinksData.youtube &&
      `https://www.youtube.com/user/${socialLinksData.youtube}`,
    socialLinksData.vimeo && `https://vimeo.com/${socialLinksData.vimeo}`,
  ].filter(Boolean); // Filter out any undefined or null values

  return (
    <footer className="max-w-7xl mx-auto pb-2" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="bg-fmg-blue-600 rounded-3xl mx-auto max-w-7xl px-6 pb-8 pt-8 lg:px-8">
        <div className="bg-white p-8 rounded-3xl xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img className="h-14 w-auto" src={logo} alt={companyInfo.name} />
            <p className="text-sm leading-6">{companyInfo.short_description}</p>

            <div className="flex space-x-6">
              {socialUrls.map((url, index) => (
                <SocialIcon
                  key={index}
                  url={url}
                  style={{ width: 32, height: 32 }} // Set size to 32px (equivalent to size-8)
                />
              ))}
            </div>
          </div>

          <div className="mt-16 grid grid-cols-1 sm:grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
            <div className="hidden sm:block">
              <h3 className="font-semibold leading-6 text-gray-900">
                Quick Links
              </h3>
              <ul className="mt-6 space-y-4"></ul>
            </div>

            <div className="hidden sm:block">
              <h3 className="font-semibold leading-6 text-gray-900">
                Information
              </h3>
              <ul className="mt-6 space-y-4"></ul>
            </div>

            <div>
              <h3 className="font-semibold leading-6 text-gray-900">
                Contact Information
              </h3>
              <dl className="mt-6 space-y-4 text-sm leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <BuildingOfficeIcon className="h-5 w-5" />
                  </dt>
                  <dd>{companyInfo.address}</dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon className="h-5 w-5" />
                  </dt>
                  <dd>
                    <a
                      className="hover:text-gray-900"
                      href={`tel:${companyInfo.phone_number}`}
                    >
                      {companyInfo.phone_number}
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon className="h-5 w-5" />
                  </dt>
                  <dd>
                    <a
                      className="hover:text-gray-900"
                      href={`mailto:${companyInfo.contact_email}`}
                    >
                      {companyInfo.contact_email}
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="pt-8 text-center text-white">
          <p className="text-xs leading-5">
            © {new Date().getFullYear()} {companyInfo.name}. Alle Rechte
            vorbehalten.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
