import React from "react";
import ReactPlayer from "react-player";

const DynamicContent = ({ content }) => {
  return (
    <div className="">
      {content.map((item, index) => {
        switch (item.type) {
          case "text":
            return (
              <div
                key={index}
                className="text-center prose sm:prose-lg md:prose-xl prose-headings:text-fmg-dark-gray max-w-none"
                // Using dangerouslySetInnerHTML to render raw HTML content
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            );

          case "buttons":
            return (
              <div
                key={index}
                className="flex space-x-4 justify-center text-center py-5"
              >
                {item.button_group.map((button, btnIndex) => (
                  <a
                    key={btnIndex}
                    href={button.button_link}
                    className="w-full uppercase text-lg px-4 py-2 border-2 border-fmg-green hover:bg-fmg-green hover:text-white transition"
                  >
                    {button.button_text}
                  </a>
                ))}
              </div>
            );

          case "image":
            return (
              <div key={index} className="w-full mx-auto">
                <img
                  src={item.image.url}
                  alt={item.caption}
                  className="object-fit mx-auto flex"
                />
              </div>
            );

          case "video":
            return (
              <div key={index} className="relative pb-[56.25%] h-0">
                {" "}
                {/* Aspect ratio for 16:9 */}
                <ReactPlayer
                  url={item.video} // Pass the video URL
                  controls={true}
                  width="100%"
                  height="100%" // Make sure the height is 100% of the aspect ratio
                  className="absolute top-0 left-0"
                />
              </div>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default DynamicContent;
