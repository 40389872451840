import React, { useState, useEffect, useRef } from "react";
import { fetchData } from "./utils/statamic-api"; // Adjust the path as needed

const Header = ({ logo }) => {
  const [menuOpen, setMenuOpen] = useState(false); // For mobile menu toggle
  const [navData, setNavData] = useState(null);

  // Desktop dropdown states
  const [openDropdowns, setOpenDropdowns] = useState({});
  const dropdownRef = useRef(null);

  // Mobile menu active tabs state
  const [activeTabs, setActiveTabs] = useState({});

  // Fetch navData from the API when the component mounts
  useEffect(() => {
    const getNavData = async () => {
      try {
        const data = await fetchData(
          "/navs/main_nav/tree?fields=title,url,show_in_menu,logo&site=de",
        );
        setNavData(data);
      } catch (error) {
        console.error("Error fetching navigation data:", error);
      }
    };

    getNavData();
  }, []);

  // Close the desktop dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle desktop dropdown open/close state
  const toggleDropdown = (index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Toggle mobile menu open/close state
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setActiveTabs({}); // Reset active tabs when closing the menu
  };

  // Utility function to determine link classes
  const getLinkClasses = (url) =>
    `${
      url === window.location.pathname
        ? "text-fmg-green-500 underline underline-offset-8"
        : "text-gray-500"
    } font-medium hover:text-fmg-green-500 hover:underline hover:underline-offset-8 transition`;

  // Recursive rendering of desktop menu items (unchanged)
  const renderMenuItems = (item, parentIndex, depth = 1, childIndex = 0) => {
    const hasChildren = item.children && item.children.length > 0;
    const isOpen = openDropdowns[parentIndex] || false;

    // If the item has a logo, render the logo as an image
    if (item.page.logo) {
      return (
        <a key={childIndex} href={item.page.url || "#"} className="block">
          <img
            src={item.page.logo.url}
            alt={item.page.title}
            className="h-10 w-auto hover:opacity-50"
          />
        </a>
      );
    }

    // Handle items without children (render as clickable links)
    if (!hasChildren) {
      return (
        <a
          key={childIndex}
          href={item.page.url || "#"}
          className={`${
            item.page.url === window.location.pathname
              ? "text-fmg-green-500 underline underline-offset-8"
              : "text-gray-500"
          } font-medium hover:text-fmg-green-500 hover:underline hover:underline-offset-8 transition`}
        >
          {item.page.title}
        </a>
      );
    }

    // Handle items with children (render dropdowns)
    if (depth === 1) {
      return (
        <div key={parentIndex} className="relative" ref={dropdownRef}>
          <button
            onClick={() => toggleDropdown(parentIndex)}
            type="button"
            className="inline-flex items-center gap-x-1 text-base leading-6 text-gray-500"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <span>{item.page.title}</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {/* Dropdown menu */}
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4`}
          >
            <div
              className={`${
                parentIndex === 0 ? "w-[80rem] text-sm" : "w-80 p-4"
              } shrink rounded-xl bg-white font-semibold leading-6 text-gray-900 shadow-lg text-center border-1 border-gray`}
            >
              <div
                className={`${
                  parentIndex === 0 ? "lg:grid-cols-7 divide-x" : ""
                } relative grid gap-y-4 text-center`}
              >
                {hasChildren &&
                  item.children.map((child, childIdx) =>
                    renderMenuItems(
                      child,
                      `${parentIndex}-${childIdx}`,
                      depth + 1,
                      childIdx,
                    ),
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Handle second-level and deeper dropdowns
    if (depth === 2) {
      // Adjust the check to childIndex to see if it's the first item within the parent
      if (childIndex === 0) {
        return (
          <div
            key={childIndex}
            className="col-span-1 flex flex-col space-y-8 text-left items-start px-8 py-4"
          >
            <div className="border-b border-gray-700 py-2 min-h-16">
              <h3 className="uppercase text-fmg-dark-gray font-bold text-sm">
                {item.page.title}
              </h3>
            </div>
            {/* Render children */}
            {hasChildren &&
              item.children.map((child, childIdx) =>
                renderMenuItems(
                  child,
                  `${parentIndex}-${childIdx}`,
                  depth + 1,
                  childIdx,
                ),
              )}
          </div>
        );
      } else {
        return (
          <div key={childIndex} className={`col-span-${item.children.length}`}>
            {hasChildren && (
              <div
                className={`grid grid-cols-${item.children.length} p-4 space-x-4`}
              >
                {item.children.map((child, childIdx) =>
                  renderMenuItems(
                    child,
                    `${parentIndex}-${childIdx}`,
                    depth + 1,
                    childIdx,
                  ),
                )}
              </div>
            )}
          </div>
        );
      }
    }

    // Handle third-level or deeper dropdowns
    if (depth === 3) {
      return (
        <div key={childIndex}>
          <div className="border-b border-gray-700 mb-5 py-2 min-h-16 items-center text-center">
            <h3 className="uppercase text-fmg-dark-gray font-bold text-sm">
              {item.page.title}
            </h3>
          </div>
          {/* Render children */}
          {hasChildren && (
            <ul className="grid space-y-3 text-base leading-7">
              {item.children.map((child, childIdx) =>
                renderMenuItems(
                  child,
                  `${parentIndex}-${childIdx}`,
                  depth + 1,
                  childIdx,
                ),
              )}
            </ul>
          )}
        </div>
      );
    }
  };

  // Recursive rendering of mobile menu items
  const renderMobileMenuItems = (
    item,
    parentIndex = "",
    index = 0,
    depth = 1,
  ) => {
    const hasChildren = item.children && item.children.length > 0;
    const key = parentIndex ? `${parentIndex}-${index}` : `${index}`;

    // If the item has a logo, render the logo as an image
    if (item.page.logo) {
      return (
        <a key={key} href={item.page.url || "#"} className="block">
          <img
            src={item.page.logo.url}
            alt={item.page.title || "Logo"}
            className="h-10 w-auto hover:opacity-50"
          />
        </a>
      );
    }

    // Handle items without children
    if (!hasChildren) {
      return (
        <a
          key={key}
          href={item.page.url || "#"}
          className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        >
          {item.page.title || "Untitled"}
        </a>
      );
    }

    // If title is null, render children directly
    if (item.page.title === null) {
      return (
        <div key={key} className="space-y-2 py-2">
          {item.children.map((child, idx) =>
            renderMobileMenuItems(child, key, idx, depth + 1),
          )}
        </div>
      );
    }

    // Handle items with children and a title
    return (
      <div key={key} className="space-y-2 py-2">
        <div>
          <button
            type="button"
            onClick={() =>
              setActiveTabs((prevState) => ({
                ...prevState,
                [key]: !prevState[key],
              }))
            }
            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50"
          >
            {item.page.title}
            <svg
              className={`h-5 w-5 flex-none transition ${
                activeTabs[key] ? "rotate-180" : ""
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {activeTabs[key] && (
          <div className=" space-y-2 ml-3">
            {item.children.map((child, idx) =>
              renderMobileMenuItems(child, key, idx, depth + 1),
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <header className="bg-white sticky top-0 z-50 shadow-sm sm:shadow-none">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:space-x-8 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Frontier Medical Group</span>
            <img
              className="h-10 w-auto md:h-12 xl:h-14"
              src={logo}
              alt="FMG Logo"
            />
          </a>
        </div>

        {/* Mobile menu button */}
        <div className="flex lg:hidden">
          <button
            onClick={toggleMenu}
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex lg:gap-x-8">
          {navData &&
            navData.data &&
            navData.data.map((item, index) => renderMenuItems(item, index))}
        </div>

        {/* Slogan */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className="flex">
            <div className="text-center group mr-5">
              <p className="text-gray-500 lowercase group-hover:text-fmg-green transition">
                bahnbrechende Einfachheit
              </p>
              <div className="mt-1 flex space-x-1 w-full justify-center">
                {Array(3)
                  .fill(null)
                  .map((_, i) => (
                    <svg
                      key={i}
                      height="7.0"
                      width="40"
                      className="fill-fmg-blue-500"
                    >
                      <rect rx="4" height="7.0" width="40" />
                    </svg>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="lg:hidden" role="dialog" aria-modal="true">
          {/* Background backdrop */}
          <div className="fixed inset-0 z-10 bg-black bg-opacity-25"></div>
          <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            {/* Mobile Menu Header */}
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Frontier Medical Group</span>
                <img className="h-8 w-auto" src={logo} alt="FMG Logo" />
              </a>
              <button
                onClick={toggleMenu}
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Mobile Menu Content */}
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-2">
                  {navData &&
                    navData.data &&
                    navData.data.map((item, idx) =>
                      renderMobileMenuItems(item, "", idx),
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
