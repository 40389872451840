const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchData = async (endpoint, options = {}) => {
  try {
    // Remove leading slash from endpoint if it exists
    const endpointRelative = endpoint.replace(/^\//, "");

    // Ensure baseURL ends with a slash
    const baseURLEndsWithSlash = baseURL.endsWith("/");
    const baseURLWithSlash = baseURLEndsWithSlash ? baseURL : baseURL + "/";

    // Construct the full URL using the baseURL
    const url = new URL(endpointRelative, baseURLWithSlash);

    // Add 'site=de' if it's not already present
    if (!url.searchParams.has("site")) {
      url.searchParams.append("site", "de");
    }

    // Perform the fetch with the modified URL
    const response = await fetch(url.toString(), options);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    // Return the parsed JSON response
    return await response.json();
  } catch (error) {
    console.error("API fetch error:", error);
    throw error;
  }
};
