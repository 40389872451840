import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "./Header";
import Footer from "./Footer";
import LogoCloud from "./LogoCloud";
import logo from "./images/fmg.svg";

const Layout = ({ children }) => {
  const Logo = logo;

  return (
    <HelmetProvider>
      <div className="">
        <Helmet>
          <script
            defer
            data-domain="frontier-medical-germany.de"
            src="https://plausible.lloydit.co.uk/js/script.js"
          ></script>
        </Helmet>
        <Header logo={Logo} />
        {/* Page content */}
        <main>{children}</main>

        <LogoCloud />
        {/* Footer */}
        <Footer logo={Logo} />
      </div>
    </HelmetProvider>
  );
};

export default Layout;
