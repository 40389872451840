import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import DynamicContent from "./DynamicContent";
import Layout from "./Layout";
import NotFound from "./NotFound";
import Gallery from "./ProductGallery";
import { fetchData } from "./utils/statamic-api";
import Loading from "./Loading";
import VariantSelector from "./components/product/variants";
import HeaderButtons from "./components/product/HeaderButtons";
import ProductInfo from "./components/product/ProductInfo";

const ProductDetail = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await fetchData(
          `/collections/products/entries?filter[slug:is]=${slug}&filter[site:is]=de`,
        );
        if (data.data.length > 0) {
          setProduct(data.data[0]);
        } else {
          setProduct(null); // Handle product not found
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [slug]);

  if (loading) return <Loading />; // Use global Loading component
  if (!product) return <NotFound />;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{product.title} | Frontier Medical Group</title>
          <meta name="description" content={product.description} />
          <meta property="og:title" content={product.title} />
          <meta property="og:description" content={product.description} />
        </Helmet>
      </HelmetProvider>
      <Layout>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="mx-auto max-w-2xl pb-16 sm:pb-18 grid grid-cols-1 lg:max-w-7xl lg:grid-cols-2 gap-8 lg:px-8 pt-5">
            <div className="lg:max-w-lg lg:self-auto space-y-5 flex justify-start flex-col order-last sm:order-first">
              <div>
                <h1 className="text-3xl font-bold tracking-tight text-fmg-dark-gray sm:text-5xl">
                  {product.title}
                </h1>
                <div className="mt-4 space-y-6">
                  <p className="text-base text-gray-500">
                    {product.description}
                  </p>
                </div>
              </div>
              <div className="space-y-5">
                <VariantSelector variants={product.variants} />
                <HeaderButtons buttons={product.header_buttons} />
                <div className="flex justify-center gap-x-10">
                  <p className="group inline-flex text-base font-medium">
                    <svg
                      className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-fmg-green"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                      />
                    </svg>
                    <span className="text-gray-500 group-hover:text-fmg-green">
                      3 Year Warranty
                    </span>
                  </p>
                  <p className="group inline-flex text-base font-medium">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-fmg-green"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                      />
                    </svg>
                    <span className="text-gray-500 group-hover:text-fmg-green">
                      5 to 7 Working Days
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <Gallery gallery={product.gallery} video={product.video} />
          </div>
          <DynamicContent className="text-center" content={product.content} />
          <ProductInfo tabs={product.product_information} />
        </div>
      </Layout>
    </>
  );
};

export default ProductDetail;
