import { Tab } from "@headlessui/react";

export default function ProductInfo({ tabs }) {
  return (
    <div className="w-full px-4">
      <Tab.Group>
        <div className="overflow-x-auto">
          <Tab.List
            className="flex justify-between space-x-8 text-lg border-b border-gray-300 flex-nowrap"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                className={({ selected }) =>
                  selected
                    ? "w-full text-center whitespace-nowrap border-b-2 py-4 font-medium border-fmg-green-500 text-fmg-green"
                    : "w-full text-center whitespace-nowrap border-b-2 py-4 font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }
              >
                {tab.title}
              </Tab>
            ))}
          </Tab.List>
        </div>
        <Tab.Panels className="mt-3 w-full">
          {tabs.map((tab) => (
            <Tab.Panel key={tab.id} className="w-full bg-white p-6">
              <div
                className="prose text-left prose-headings:text-fmg-dark-gray max-w-none"
                dangerouslySetInnerHTML={{ __html: tab.content }}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
